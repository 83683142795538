<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Case Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                </label>
                <multiselect
                  v-model="form.status"
                  :options="['active', 'flagged', 'finished', 'trash']"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <div class="form-group">
                <validation-provider
                  #default="{ errors }"
                  name="Case Status"
                  rules="required"
                >
                  <label class="input-label form-label"
                    ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                  </label>
                  <multiselect
                    v-model="form.caseStatus"
                    :options="[
                      'queued',
                      'in-progress',
                      'failed',
                      'unsupported',
                      'done',
                      'in-approval',
                      'hidden',
                    ]"
                    :multiple="false"
                  >
                  </multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="6">
              <label class="form-label" for="cars">{{ $t("Approved") }}</label>

              <input
                id="isApproved"
                class="form-check-input-custom ml-2"
                v-model="form.isApproved"
                type="checkbox"
                name="isApproved"
              />
            </b-col>
            <b-col md="12">
              <label class="input-label form-label">
                {{ $t("Comments") }}
              </label>
              <textarea class="form-control" v-model="form.details"></textarea>
            </b-col>
            <b-col md="6" class="mt-3">
              <div v-if="form.results && form.results['upper_image']" class="">
                <h4>{{ $t("Upper Jaw Rating") }}</h4>
                <StarRating
                  :value="(form.ratings && form.ratings.upper_case_rating) || 0"
                  @setRatingValue="setRatingValue($event, 'upper')"
                />
              </div>
            </b-col>
            <b-col md="6" class="mt-3">
              <div v-if="form.results && form.results['lower_image']" class="">
                <h4>{{ $t("Lower Jaw Rating") }}</h4>
                <StarRating
                  :value="(form.ratings && form.ratings.lower_case_rating) || 0"
                  @setRatingValue="setRatingValue($event, 'lower')"
                />
              </div>
            </b-col>
            <b-col md="12" class="mt-3">
              <PreviewOriginalModel
                ref="previewOriginalRef"
                v-if="renderOriginalComponent"
                :upperFileId="form.files.upper"
                :lowerFileId="form.files.lower"
              />
            </b-col>
            <b-col md="6" class="mt-3">
              <h4 class="input-label form-label mb-1">
                {{ $t("Upper Jaw Results") }}
              </h4>
              <input
                type="file"
                @change="addFile($event, 'upper', 'results')"
                accept=".stl, .obj"
              />
              <div
                v-if="form.results && form.results.upper"
                class="d-flex gap-2"
              >
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      handlePreviewResultClick([form.results['upper']], 'Upper')
                    "
                  >
                    <span>{{ $t("Preview Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      downloadFiles(
                        [form.results['upper_rotated']] || [
                          form.results['upper'],
                        ]
                      )
                    "
                  >
                    <span>{{ $t("Download Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['upper']])"
                  >
                    <span>{{ $t("Download without Rotation") }}</span>
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6" class="mt-3">
              <h4 class="input-label form-label mb-1">
                {{ $t("Lower Jaw Results") }}
              </h4>
              <input
                type="file"
                @change="addFile($event, 'lower', 'results')"
                accept=".stl, .obj"
              />
              <div
                v-if="form.results && form.results.lower"
                class="d-flex gap-2"
              >
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      handlePreviewResultClick([form.results['lower']], 'Lower')
                    "
                  >
                    <span>{{ $t("Preview Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      downloadFiles(
                        [form.results['lower_rotated']] || [
                          form.results['lower'],
                        ]
                      )
                    "
                  >
                    <span>{{ $t("Download Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['lower']])"
                  >
                    <span>{{ $t("Download without Rotation") }}</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="replicateCase"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Recalculate Case") }}</span>
        </b-button>
        <b-button
          variant="primary"
          @click="updateCase"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Case") }}</span>
        </b-button>
      </div>
    </validation-observer>
    <div class="c-modal" v-show="showPreviewModal">
      <div class="c-modal-content">
        <div class="c-modal-header">
          <h3>{{ $t(`Preview ${previewJawType} Jaw`) }}</h3>
          <div class="x-icon" @click="closePreviewResultModal">
            <feather-icon size="16" icon="XIcon" />
          </div>
        </div>
        <div class="c-modal-body">
          <PreviewResultModel v-if="showPreviewModal" :results="file" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-quill-editor";
import { required, email } from "@validations";
import StarRating from "@/components/elements/StarRating.vue";
import PreviewResultModel from "@/components/PreviewResultModel.vue";
import FileService from "@/services/file.service";
import PreviewOriginalModel from "./components/PreviewOriginalModel.vue";
export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    VueEditor,
    StarRating,
    PreviewResultModel,
    PreviewOriginalModel,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("case-list"),
          to: "/my-cases",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    ...mapGetters("caseLists"),
  },
  data() {
    return {
      form: {
        name: "",
        details: "",
        status: "",
        caseStatus: "",
        results: {},
        ratings: {},
        files: {},
        isApproved: false,
      },
      showPreviewModal: false,
      file: null,
      previewJawType: "",
      renderOriginalComponent: false,
    };
  },
  async mounted() {
    await this.loadItems();
  },
  methods: {
    async replicateCase() {
      this.$swal({
        title: this.$t("Do you want to recalculate this record?"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, recalculate it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/replicateCase", {
              caseId: this.$route.params.id,
              extensions: [{ id: "opg-to-3d" }],
              isAdminPortal: true,
            })
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
    async loadItems() {
      this.renderOriginalComponent = false;
      await this.$store
        .dispatch("caseLists/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.form.name = response?.name ?? "";
          this.form.details = response?.details ?? "";
          this.form.status = response?.status ?? null;
          this.form.caseStatus = response?.caseStatus ?? "";
          this.form.results = response?.results ?? {};
          this.form.ratings = response?.ratings ?? {};
          this.form.files = response?.files ?? {};
          this.form.isApproved = response?.isApproved;
        });
      this.renderOriginalComponent = true;
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    addFile(event, jawType, fileType) {
      try {
        this.$store.commit("showLoader", true);
        const file = event.target.files[0];

        FileService.uploadFile(file, { visibility: 0 }, 0, 0.33)
          .then((response) => {
            this.$store.commit("showLoader", false);
            this.$store.dispatch(
              `caseLists/${
                fileType === "results" ? "saveResults" : "save-files"
              }`,
              {
                caseId: this.$route.params.id,
                ...(jawType == "upper"
                  ? { upper: response?.data[0]?.id }
                  : { lower: response?.data[0]?.id }),
              }
            );
          })
          .finally(async () => {
            await this.loadItems();
            this.$store.commit("showLoader", false);
          });
      } catch (e) {}
    },
    closePreviewResultModal() {
      this.showPreviewModal = false;
    },
    handlePreviewResultClick(array, jawType) {
      this.file = array;
      this.previewJawType = jawType;
      this.showPreviewModal = true;
    },
    async setRatingValue(event, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: this.$route.params.id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
    async updateCase() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.$store.commit("showLoader", true);
          await this.$nextTick();
          setTimeout(async () => {
            try {
              let uploadedIds = { upperId: null, lowerId: null };
              if (this.$refs.previewOriginalRef) {
                uploadedIds =
                  await this.$refs.previewOriginalRef.uploadData2DTo3D();
              }
              const payload = {
                caseId: this.$route.params.id,
                ...(uploadedIds?.upperId
                  ? {
                      upper: uploadedIds.upperId?.id,
                      upper_rotation: uploadedIds?.upper_rotation,
                      upper_translation: uploadedIds?.upper_translation,
                      upper_matrixWorld: uploadedIds?.upper_matrixWorld,
                      upper_quaternion: uploadedIds?.upper_quaternion,
                      upper_scale: uploadedIds?.upper_scale,
                      upper_matrixLocal: uploadedIds?.upper_matrixLocal,
                    }
                  : {}),
                ...(uploadedIds?.lowerId
                  ? {
                      lower: uploadedIds.lowerId?.id,
                      lower_rotation: uploadedIds?.lower_rotation,
                      lower_translation: uploadedIds?.lower_translation,
                      lower_matrixWorld: uploadedIds?.lower_matrixWorld,
                      lower_quaternion: uploadedIds?.lower_quaternion,
                      lower_scale: uploadedIds?.lower_scale,
                      lower_matrixLocal: uploadedIds?.lower_matrixLocal,
                    }
                  : {}),
              };
              if (payload.upper || payload.lower) {
                await this.$store.dispatch("caseLists/saveFiles", payload);
              }
              await this.$store.dispatch("caseLists/update", {
                id: this.$route.params.id,
                data: {
                  ...this.form,
                },
              });
              if (this.form.isApproved == true) {
                this.form.caseStatus = "done";
              }
              this.$store.commit("showLoader", false);
            } catch (e) {
              console.error(e);
            } finally {
              this.$store.commit("showLoader", false);
            }
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
