<template>
  <div class="auth-wrapper auth-v2">
    <ParticleAnimation />

    <div class="auth-left" ref="authParentBox">
      <div class="auth-box" ref="authChildBox">
        <b-link class="">
          <img
            src="@/assets/images/svg/logo.svg"
            style="width: 270px; padding-top: 8px"
          />
        </b-link>
        <img src="@/assets/images/svg/teethlogin.png" class="teeth" alt="" />
        <img src="@/assets/images/svg/vector.png" class="vectors" alt="" />
        <p>
          {{ $t("Efficiency & Quality") }} -<br />{{
            $t("let AI revolutionize your work")
          }}!
        </p>
      </div>
    </div>
    <div class="auth-right">
      <div class="auth-detail">
        <div class="auth-detail-logo">
          <h2>{{ $t("Welcome") }}</h2>
        </div>
        <p>
          {{
            $t(
              "Please login to your account and start a new journey into dentistry 4.0"
            )
          }}
        </p>

        <!-- form -->
        <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <label for="login-password"> {{ $t("Email") }} </label>
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password"> {{ $t("Password") }} </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t("Sign in") }}
              </b-button>
            </b-form>
          </validation-observer>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ParticleAnimation from "@/components/ParticleAnimation.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import AuthService from "../services/auth.service";
import NotificationService from "../services/notification.service";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ParticleAnimation
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login/login.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  async beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) this.$router.push({ name: "home" });
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      this.sideImg = require("@/assets/images/pages/login/login.png");
      return this.sideImg;
    },
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  methods: {
    async validationForm() {
      this.$refs.loginValidation.validate().then(async (success) => {
        if (success) {
          try {
            let response = await this.$store.dispatch("auth/login", {
              mail: this.userEmail,
              password: this.password,
            });
            const userId = response?.data?.token_info?.user_id;
            this.$store.commit("auth/set_authenticated", true);
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.user) ?? "{}"
            );
            localStorage.setItem("authenticated", true);
            this.$router.push({ name: "home" });
          } catch (err) {}
        }
      });
    },
    setWidth() {
      const parentWidth = this.$refs.authParentBox.offsetWidth;
      const parentheight = this.$refs.authParentBox.offsetHeight;
      this.$refs.authChildBox.style.width = `${parentWidth}px`;
      this.$refs.authChildBox.style.height = `${parentheight}px`;
    },
  },
  
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
