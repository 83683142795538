<template>
  <div class="">
    <PageHeader :items="items" />
    <div class="row">
      <div v-if="$can('document-assignment.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/document-assignment">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'permissionNIcon'" />
            <p class="setting-card-text">{{ $t("Document Assignment") }}</p>
          </div>
        </router-link>
      </div>
      <div v-if="$can('mail-template-assignment.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/mail-template-assignment">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'permissionNIcon'" />
            <p class="setting-card-text">
              {{ $t("Mail Template Assignment") }}
            </p>
          </div>
        </router-link>
      </div>
      <div v-if="$can('invoice-reminder-level.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/invoice-reminder-levels">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'permissionNIcon'" />
            <p class="setting-card-text">{{ $t("Reminder Levels") }}</p>
          </div>
        </router-link>
      </div>

      <div v-if="$can('permission.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/permissions">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'permissionNIcon'" />
            <p class="setting-card-text">{{ $t("Permissions") }}</p>
          </div>
        </router-link>
      </div>
      <div v-if="$can('user.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/users">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'userNIcon'" />
            <p class="setting-card-text">{{ $t("Users") }}</p>
          </div>
        </router-link>
      </div>
      <div v-if="$can('role.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/roles">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'RolesNIcon'" />
            <p class="setting-card-text">{{ $t("Roles") }}</p>
          </div>
        </router-link>
      </div>
      <div v-if="$can('api-key.list')" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/api-keys">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'apiKeyNIcon'" />
            <p class="setting-card-text">{{ $t("Api Keys") }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { BTabs, BTab } from "bootstrap-vue";
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: {
    BTabs,
    BTab,
    ElementIcon,
    PageHeader,
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          active: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.setting-card-container {
  border: 1px solid rgba(17, 37, 62, 0.2);
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  gap: 10px;
  margin-bottom: 20px;
  padding: 17px 30px;

  &:hover {
    border: 1px solid #38414a;
  }
}

// .setting-card-container:hover {
//   background-color: #38414a;
// }

// .setting-card-container:hover i {
//     color: white;
// }

// .setting-card-container:hover .setting-card-text {
//     color: white;
// }

.setting-card-text {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
</style>
